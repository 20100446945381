import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { XfXApi, SetBasicAuth, SetAADAuth, Logout, ConfigureResponseInterceptor, SetXfxSession } from 'api/XfXApi';
import { useMsal } from "@azure/msal-react";
import { AadLogout, AadGetTokenData } from '../contexts/aad'
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig"
import { Privileges } from "privileges"
import notify from 'devextreme/ui/notify';
import { InteractionStatus } from "@azure/msal-browser";
import { useTranslation } from 'react-i18next';
import { EventType } from "@azure/msal-browser";

const XFX_TENANT_ID = "xfx-tenant-id"

const AuthProvider = (props) => {
  const { t } = useTranslation()
  const [userCantLogin, setUserCantLogin] = useState(false);
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [userSettings, setUserSettings] = useState();
  const [privileges, setPrivileges] = useState([]);
  const [features, setFeatures] = useState([]);
  const [privilegesForOrganization, setPrivilegesForOrganization] = useState([]);
  const [disabledViews, setDisabledViews] = useState([])
  const [maintenanceMode, setMaintenanceMode] = useState();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [tenantSelector, setTenantSelector] = useState(null);
  const [currentTenantId, setCurrentTenantId] = useState()
  const [canChangeTenant, setCanChangeTenant] = useState()

  useEffect(() => {
    XfXApi.Info.apiInfoGet().then((r) => {
      setMaintenanceMode(r.data.maintenanceMode)
    })

    ConfigureResponseInterceptor(
      null, 
      error  => {
        if (error.response.status === 403) {
          notify(t("#_auth_1"), 'error', 10000)
        }
      }
    )

    const callbackId = instance.addEventCallback((message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const userLogin = message.payload.account.username;
          notify(t("#_login_message", { userLogin: userLogin }), 'success', 10000)
        }
    });

    return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && !maintenanceMode) {
      const getTokenData = AadGetTokenData(instance, accounts[0]);

      getTokenData().then(async tokenData => {
        SetAADAuth(getTokenData)

        const { data: tenants } = await XfXApi.Info.apiInfoTenantsGet()
        SetXfxSession(crypto.randomUUID())
        setCanChangeTenant(tenants.length > 1)
        let tenantId

        if (tenants.length === 1) {
          tenantId = tenants[0].id
        } else {
          const lastTokenId = localStorage.getItem(XFX_TENANT_ID)
          if (lastTokenId !== undefined && tenants.some(x => x.id === lastTokenId)) {
            tenantId = lastTokenId
          } else {
            if (tenantSelector === null) {
              console.error("Tenant selector not set")
              return
            }
            tenantId = await tenantSelector.select(tenants)
            localStorage.setItem(XFX_TENANT_ID, tenantId);
          }
        }

        XfXApi.SetTenantId(tenantId)
        setCurrentTenantId(tenantId)
        await initUserInTenantContext(tokenData.account.name)
      })
    }
  }, [inProgress, isAuthenticated, currentTenantId])

  const initUserInTenantContext = async (userName) => {
    const x = await checkAuth();
    if (x?.isOk) {
      setUserCantLogin(false);
      setUser(userName);
    }
    else if (x?.noAccountOrNoPriv) {
      setUserCantLogin(true);
      notifyLoginFailed();
    }
  }

  const notifyLoginFailed = async () => {
    notify({
      type: 'error',
      displayTime: 2147483647,
      contentTemplate: (contentElem_1) => {
        contentElem_1.innerHTML = t('#_auth_loginfailed');
        contentElem_1.addEventListener('click', () => {
          signOut();
        });
    }
  });
};

  const checkAuth = async () => {
    let isOk = false;
    return await XfXApi.Info2.apiTenantIdInfo2UserContextGet(XfXApi.GetTenantId()).then(async x => {
      isOk = x.status === 200
      if (isOk) {
        if (x.data.privileges.length > 0) {
          setPrivileges(x.data.privileges)
          setPrivilegesForOrganization(x.data.privilegesForOrganization)
          setUserId(x.data.userId)
          setUserSettings(x.data.userSettings !== undefined ? { ...x.data.userSettings, settings: JSON.parse(x.data.userSettings.settings) } : null)
          setDisabledViews(x.data.disabledViews)
          setFeatures(x.data.features)
          setIsAdmin(x.data.isAdmin)
          return {
            isOk: true
          };
        } else {
          notify(t("#_auth_2"), 'error', 2000);
        }
      }
      return {
        isOk: false
      };
    }).catch((err) => {
      const response = err.response
      if (isAuthenticated && (response.status === 401 || response.status === 403)) {
        return {
          isOk: false,
          noAccountOrNoPriv: true
        };
      } else {
        if (!isAuthenticated) {
          notify(t("#_auth_4"), 'error', 2000);
        }
      }
    })
  }

  const signIn = useCallback(async (login, password) => {
    SetBasicAuth(login, password)

    return checkAuth().then(x => {
      if (x.isOk) {
        setUser(t("#_auth_5"));
      }
    });
  }, []);

  const aadSignIn = useCallback(async () => {
    await instance.loginRedirect(loginRequest);
  }, []);


  const signOut = async () => {
    Logout()
    setUser();
    setUserSettings()
    localStorage.removeItem(XFX_TENANT_ID)
    if (!maintenanceMode) {
      await AadLogout(instance, accounts)
    }
  };
  const hasPrivilege = (priv, org) => {
    if (org === undefined || org === null) {
      return privileges.some(x => x === priv)
    }
    else {
      if (privilegesForOrganization[org] === undefined)
        return false;

      return privilegesForOrganization[org].some(x => x === priv);
    }
  }

  const hasAnyPrivilege = (arrayOfPrivs, org) => {
    return arrayOfPrivs.some(p => hasPrivilege(p, org))
  }

  const clearTenant = () => {
    localStorage.removeItem(XFX_TENANT_ID)

    setCurrentTenantId(null)
    window.location.reload()
  }

  const hasFeature = (feature) => features.some(x => x === feature)

  return (
    <AuthContext.Provider value={{
      user, setSelectTenant: setTenantSelector, signIn, signOut, aadSignIn, maintenanceMode, privileges: privileges, hasPrivilege, hasAnyPrivilege, userId, userCantLogin, userSettings, setUserSettings, disabledViews, checkAuth,
      clearTenant, canChangeTenant, currentTenantId, notifyLoginFailed, hasFeature, isAdmin
    }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, Privileges }
