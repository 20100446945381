import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericToolbar from '../Toolbars/GenericToolbar';
import { OdataEndpoints, KsefixEndpoints } from 'api/XfXApi';
import notify from 'devextreme/ui/notify';
import GenericPopup from "components/popup/GenericPopup"
import { useState, useRef, useEffect } from 'react';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import ImporterEditor from "./ImporterEditor"
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import useDataGridFilter from 'utils/useDataGridFilter'
import useFormErrors from 'utils/useFormErrors';
import { columns } from './const';
import axios, { AxiosResponse } from 'axios';

const ConfigImport = () => {
    const initialFilter: any[] = []

    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<any[]>([])
    const vgForm = useRef(null);
    const emptyState = {
        name: null,
    }
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter, queryArguments: undefined })
    const [state, setState] = useState(emptyState)
    const [file, setFile] = useState<any[]>()
    const [selectedItem, setSelectedItem] = useState<any>(null)
    const [reloadKey, setReloadKey] = useState(0)
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const dataGridRef = useRef(null);

    const { setErrors, getFirstError, noErrors, clearError, clearAllErrors } = useFormErrors();
    const { t } = useTranslation()
    const defaultSort = { selector: "CreationDateUtc", desc: true };

    useEffect(() => {
        setData(columns({ t }))
    }, [t, setData])

    const openPopup = () => {
        setState({ ...emptyState })
        setFile([])
        popup.show()
    }

    const saveFile = (response: AxiosResponse<Blob>, fileName: string): void => {
        if (response.status !== 200) return;
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const downloadDataSource = async () => {
        try {
            if (selectedItem != null) {
                const url = KsefixEndpoints.GetConfigFile(selectedItem.Id);
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

                const fileName = `${selectedItem.Name}_${formattedDate}.json`;

                const resp = await axios.request<Blob>({
                    url: url,
                    method: "GET",
                    responseType: 'blob',
                });
                saveFile(resp, fileName)
            }
        }
        catch (error: any) {
        }
    }

    const downloadReportContent = async () => {
        try {
            if (selectedItem != null) {
                const url = KsefixEndpoints.GetReportFile(selectedItem.Id);
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

                const fileName = `ImportLog_${selectedItem.Name}_${formattedDate}.csv`;

                const resp = await axios.request<Blob>({
                    url: url,
                    method: "GET",
                    responseType: 'blob',
                });
                saveFile(resp, fileName)
            }
        }
        catch (error: any) {
        }
    }

    const saveAction = async () => {
        try {
            var formData = new FormData()
            if (file !== null && file !== undefined && file.length > 0) {
                formData.append("file", file[0])
            }
            if (state.name !== null && state.name !== undefined) {
                formData.append("name", state.name)
            }

            var response = await axios.post(KsefixEndpoints.ConfigImport(), formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });

            if (response.status === 200) {
                notify({
                    message: t("#_ImportConfig_SuccessMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
                return true;
            }
        } catch (error: any) {
            if (error.response.status === 400) {
                notify({
                    message: t("#_ImportConfig_ErrorMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
            }
            else if (error.response.status === 422) {
                setErrors(error.response.data);
            }
        }
    }

    const popupToolbarItems = commonPopupToolbarItems({
        onSave: async () => {
            const sent = await saveAction()
            if (sent) {
                popup.close()
                clearAllErrors()
                setState({ ...emptyState })
                setFile([])
                setReloadKey(reloadKey + 1);
            }
        },
        onCancel: () => {
            popup.close()
            clearAllErrors()
            setState({ ...emptyState })
            setFile([])
        },
        t: t,
        saveLabel: undefined, cancelLabel: undefined,
        includeDraft: undefined, onSaveDraft: undefined, saveButtonOptions: undefined,
        cancelButtonOptions: undefined, saveDraftLabel: undefined,
        saveDraftButtonOptions: undefined, loading: undefined,
    })

    const editor = ImporterEditor({
        state: state,
        vgForm: vgForm,
        setFile: setFile,
        file: file,
        t: t,
        getFirstError, noErrors, clearError
    })

    // @ts-ignore
    const popup = GenericPopup({
        onHiding: () => popup.close(),
        content:
            <div>
                {editor}
            </div>,
        toolbarItems: popupToolbarItems,
        title: t("#_ImportConfig_ImportConfigTitle"),
        width: "812",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const toolbarButtons = [
        { icon: 'add', text: t("button-config-import"), onClick: openPopup },
        { icon: 'download', text: t("download-data-source-button"), onClick: downloadDataSource, disabled: selectedItem === null },
        { icon: 'export', text: t("download-report-button"), onClick: downloadReportContent, disabled: selectedItem === null || selectedItem.Status !== 'Completed' },
    ]

    return (
        <>
            {popup?.popup}
            <BreadCrumbs node={getNode({ componentName: 'ConfigImport', t })} breadCrumbs={undefined}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("licenses")} buttons={toolbarButtons} customHeaderRenderer={undefined} toolbarId={'configImportToolbar'}></GenericToolbar>
            <div id="dataGrid-documentTemplates" className="dataGrid">
                {/* 
                    // @ts-ignore */
                    GenericOdataGrid({
                        ref: dataGridRef,
                        data: data,
                        endpoint: OdataEndpoints.ConfigImport(),
                        setSelectedItem: setSelectedItem,
                        defaultFilter: dataGridFilter,
                        defaultSort: defaultSort,
                        updateDataGridFilter: updateDataGridFilter,
                        gridKey: reloadKey,
                        dataSourceOptions: dataSourceOptions,
                        setDataSourceOptions: setDataSourceOptions,
                        blockAutoRefresh: blockAutoRefresh,
                        setBlockAutoRefresh: setBlockAutoRefresh
                    })
                }
            </div>
        </>
    );
}

export default ConfigImport 