import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { XfXApi } from 'api/XfXApi';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import notify from "devextreme/ui/notify"
import Form, {
    Label,
    SimpleItem, 
    GroupItem
  } from 'devextreme-react/form';
import BreadCrumbs  from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';


const ConfigS3 = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    id: null,
    accessKey: '',
    secret: '',
    sessionToken: null
  })
    
  useEffect(() => {
    XfXApi.ConfigS3Api.apiTenantIdConfigs3Get(XfXApi.GetTenantId())
      .then(resp => { if (resp.data) { setForm(resp.data) } })
      .catch(() => { });
  }, []);


  const onSaved = async () =>
  {
    try {
      const response = form?.id ? await XfXApi.ConfigS3Api.apiTenantIdConfigs3Put(XfXApi.GetTenantId(), form)
                                : await XfXApi.ConfigS3Api.apiTenantIdConfigs3Post(XfXApi.GetTenantId(), form)
      if (response.status === 200) {
        notify({
          message: t("#_securitysavesuccess"),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
        if (response.data && response.data !== '')
          setForm({ ...form, id: response.data })
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: error?.response?.data?.XfxDetails?.join('\r\n') ?? error.response.data.join('\r\n'),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
    }
  }   

  const ConfigS3Form = () => (
    <>
      <Form
        id="form"
        labelMode="static"
        minColWidth={300}
        colCount={3}
        formData={form}>
        <GroupItem colCount={3} colSpan={3}>
            <SimpleItem dataField="accessKeyID" editorType="dxTextBox" value={form.accessKeyID}>
              <Label text={t('AWSAccessKeyID')} />
            </SimpleItem>
            <SimpleItem dataField="secretAccessKey" editorType="dxTextBox" value={form.secretAccessKey}>
              <Label text={t('AWSSecretAccessKey')}/>
            </SimpleItem>
            <SimpleItem dataField="sessionToken" editorType="dxTextBox" value={form.sessionToken}>
              <Label text={t('AWSSessionToken')}/>
            </SimpleItem>
        </GroupItem>
      </Form>
      <Toolbar className='basicToolbar'>
        <Item
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={{
            text: t('#_savecancelpopup_1'),
            onClick: onSaved
          }}
        />
      </Toolbar>
    </>
  );

  return (
    <>
      <BreadCrumbs node={getNode({ componentName: 'ConfigS3', t })}></BreadCrumbs>
      <ConfigS3Form /> 
    </>
  );
};

export default ConfigS3;
