import { useState, useMemo, useRef, useEffect } from "react"
import GenericPopup from "components/popup/GenericPopup"
import { useTranslation } from 'react-i18next';
import Editor, { EditorHandles } from './Editor'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useUserSettingsContext } from "contexts/UserSettingsProvider";
import { EditorTokensSource, EditorType, Form as FormModel, IOrganization, UserInfo } from "./model"
import { downloadXmlForDraftDto } from "./downloadFile";
import { createOrUpdateDraft } from "../Actions/CreateOrUpdateDraft";
import { GetTokens } from "../Actions/GetTokens";
import { sendDraft } from "./Actions/sendDraft";
import { getEditor } from "./getEditorModel";
import { addPrefix } from "./recordHelpers";
import { XfXApi } from "api/XfXApi";
import { BasicOrganizationInfoResponse, OrganizationDto } from "api/openapi";
import notify from "devextreme/ui/notify";
import { DraftStatus } from "api/openapi-invoice";


export const EditorInPopup = (onClosed: () => void, userInfo: UserInfo, setBlockAutoRefresh: () => void) => {
    const { t } = useTranslation()
    const [currentModel, setCurrentModel] = useState<FormModel | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [invoiceValues, setInvoiceValues] = useState<Record<string, any>>()
    const [beforeCorrectionValues, setBeforeCorrectionValues] = useState<Record<string, any> | null>(null)
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [editorType, setEditorType] = useState<EditorType>(EditorType.Vat)
    const [tokensSource, setTokensSource] = useState<EditorTokensSource>(EditorTokensSource.NewInvoice)
    const [selectedEditorType, setSelectedEditorType] = useState<EditorType>(EditorType.Vat)
    const [title, setTitle] = useState("")
    const [draftId, setDraftId] = useState<string | null>(null)

    const { getOrganizationContextSettings }: any = useUserSettingsContext()
    const organizationContext = getOrganizationContextSettings()
    const organizationNIP = organizationContext?.NIP || ""
    const organizationId = organizationContext?.Id || ""
    const [organization, setOrganization] = useState<OrganizationDto | null>(null)
    const [availableOrganizations, setAvailableOrganizations] = useState<OrganizationDto[] | undefined>(undefined)

    useEffect(() => {
        XfXApi.Organization.apiTenantIdOrganizationEditorGet(XfXApi.GetTenantId()).then((response) => {
            setAvailableOrganizations(response.data)
            const organization = response.data.find((org) => org.id === organizationId)?? null
            setOrganization(organization)
        })
    }, [organizationId])

    const editorRef = useRef<EditorHandles>(null);

    const editor = useMemo(() => {
        if (currentModel === undefined) return <LoadIndicator key="invoice-editor-loading" id="large-indicator" height={60} width={60} />

        if (invoiceValues === undefined || availableOrganizations === undefined) return <LoadIndicator key="invoice-editor-loading" id="large-indicator" height={60} width={60} />

        return <Editor ref={editorRef} key={"invoice-editor"} editorType={editorType} tokensSource={tokensSource} selectedEditorType={selectedEditorType}
            model={currentModel} tokens={invoiceValues}
            beforeCorrectionTokens={beforeCorrectionValues}
            getXml={downloadXmlForDraftDto}
            userInfo={userInfo} availableOrganizations={availableOrganizations}></Editor>
    }, [currentModel, invoiceValues, editorType, tokensSource, selectedEditorType, userInfo, beforeCorrectionValues, availableOrganizations])

    const saveDraft = async (isReady: boolean): Promise<{ isSuccessful: boolean, draftId: string | null }> => {
        const draftData = editorRef.current?.getTokensForDraft()
        let tokens = draftData?.tokens
        if (tokens) {
            if (beforeCorrectionValues !== null) {
                tokens = { ...tokens, ...addPrefix(beforeCorrectionValues) }
            }
            const result = await createOrUpdateDraft({
                draftId, organizationId: organizationNIP, tokens,
                keysForXml: draftData?.keysForXml || [],
                isReady: isReady
            })
            if (result.isSuccessful && result.draftId !== draftId) {
                setDraftId(result.draftId)
            }
            if (isReady === true && result.status !== DraftStatus.Ready) {
                notify(t("editor-failed-to-set-ready"), "error", 2000)
            }

            return result
        }
        return { isSuccessful: false, draftId: null }
    }

    const popup = GenericPopup({
        buttons: [
            {
                text: t("editor-save-draft"),
                location: "after",
                action: async (popup: any) => {
                    if (await saveDraft(false)) {
                        popup.close()
                        onClosed()
                    }
                }
            },
            {
                text: t("editor-save-ready"),
                location: "after",
                action: async (popup: any) => {
                    if (await saveDraft(true)) {
                        popup.close()
                        onClosed()
                    }
                }
            },
            {
                text: t("editor-send-to-ksef"),
                location: "after",
                action: async (popup: any) => {
                    const { isSuccessful, draftId } = await saveDraft(true)
                    if (isSuccessful) {
                        if (draftId !== null) {
                            if (await sendDraft(draftId, organizationNIP, () => { })) {
                                popup.close()
                                onClosed()
                            }
                        }
                    }
                }
            },
            {
                text: t("editor-cancel"),
                location: "after",
                action: (popup: any) => {
                    popup.close()
                    onClosed()
                }
            }],
        title: title,
        width: "90%",
        height: "90%",
        content: <div>
            {loading && <LoadIndicator key="invoice-editor-loading" id="large-indicator" height={60} width={60} />}
            {!loading && editor}
        </div>,
        toolbarItems: undefined,
        onHiding: () => popup.close(),
        showTitle: true,
        isVisible: isPopupVisible,
        setIsVisible: setIsPopupVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const show = async (type: EditorType, contentId: string | null, tokensSource: EditorTokensSource) => {
        setLoading(true)

        const tokens = await GetTokens(organization, contentId, type, tokensSource)
        if (tokens === undefined) {
            setLoading(false)
            return
        };

        const editableTokens = tokens.editableTokens
        const orginalType = tokens.orginalType
        const beforeCorrectionValues = tokens.beforeTokens

        setEditorType(orginalType)
        setSelectedEditorType(type)
        setTokensSource(tokensSource)
        const editor = getEditor(t, orginalType, editableTokens, userInfo, tokensSource)

        if (editor.model !== null) {
            setCurrentModel(editor.model)
        }
        setTitle(editor.title)

        if (tokensSource === EditorTokensSource.Draft) {
            setDraftId(contentId)
        } else {
            setDraftId(null)
        }

        await popup.show()
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 300);
        })



        if (editableTokens === null) {
            setInvoiceValues({})
            popup.close()
            setLoading(false)

        } else {

            setBeforeCorrectionValues(beforeCorrectionValues || {})
            setInvoiceValues(editableTokens)
        }
        setLoading(false)
    }

    return { popup: popup.popup, show: show }
}


