export enum Privileges {
    AuthenticatedUser = "authenticated_user",
    AllColumnsAccess = "all_columns_access",
    UserManagement = "user_management",
    SystemManagement = "system_management",
    OrganizationsManagement = "organizations_management",
    ContractorsManagement = "contractors_management",
    RolesManagement = "roles_management",
    ModulesManagement = "modules_management",
    DataSourceManagement = "data_source_management",
    LicenseManagement = "license_management",
    PipelinesManagement = "pipelines_management",
    CreateBatch = "system_management",
    BatchPackagesGrid = "system_management",
    KsefManagement = "ksef_management",
    ReportBuilder = "report_builder",
    DispatchTags = "dispatch_tags",
    DispatchPostInvoice = "dispatch_post_invoice",
    DispatchSalesProcessing = "dispatch_sales_processing",
    DispatchSalesAcceptance = "dispatch_sales_acceptance",
    DispatchSalesFailed = "dispatch_sales_failed",
    DispatchSalesBatchSend = "dispatch_sales_batchsend",
    DispatchSelfInvoicingProcessing = "dispatch_selfinvoicing_processing",
    DispatchSelfInvoicingAcceptance = "dispatch_selfinvoicing_acceptance",
    DispatchSelfInvoicingFailed = "dispatch_selfinvoicing_failed",
    DispatchSelfInvoicingBatchSend = "dispatch_selfinvoicing_batchsend",
    DispatchOtherProcessing = "dispatch_other_processing",
    DispatchOtherAcceptance = "dispatch_other_acceptance",
    DispatchOtherFailed = "dispatch_other_failed",
    DispatchOtherBatchSend = "dispatch_other_batchsend",
    DispatchCreateInvoice = "dispatch_create_invoice",
    DispatchEditInvoice = "dispatch_edit_invoice",
    ReceptionTags = "reception_tags",
    ReceptionPurchaserProcessing = "reception_purchaser_processing",
    ReceptionPurchaserAcceptance = "reception_purchaser_acceptance",
    ReceptionPurchaserFailed = "reception_purchaser_failed",
    ReceptionExtSalesProcessing = "reception_ext_sales_processing",
    ReceptionSelfInvoicingProcessing = "reception_selfinvoicing_processing",
    ReceptionSelfInvoicingAcceptance = "reception_selfinvoicing_acceptance",
    ReceptionSelfInvoicingFailed = "reception_selfinvoicing_failed",
    ReceptionExtIssuerProcessing = "reception_ext_issuer_processing",
    ReceptionAdditionalBuyerProcessing = "reception_additional_buyer_processing",
    ReceptionAdditionalBuyerAcceptance = "reception_additional_buyer_acceptance",
    ReceptionAdditionalBuyerFailed = "reception_additional_buyer_failed",
    ReceptionOtherProcessing = "reception_other_processing",
    ReceptionMarkAsReceived = "reception_mark_as_received",
    Sales = "sales",
    Purchaser = "purchaser",
    Other = "other",
    IntegrationReception = "integration_reception",
    IntegrationDispatch = "integration_dispatch",
    SelfInvoicingPermissions = "self_invocing_permissions",
    DispatchInvoiceDraft = "dispatch_invoice_draft",
    DraftCreateNew = "draft_create_new",
    DraftCorrectionEditAddress = "draft_correction_edit_address",
    DraftCorrectionEditFiscal = "draft_correction_edit_fiscal"
}
