import { renderCreationDateCell } from 'utils/cellRenderers'

export const columns = ({ t }) => 
[ 
    { dataField: "Id", dataType: "string", visible: false },
    { dataField: "Name", dataType: "string", caption: t("#_ImportConfig_DataSourceColumn"), width: "30%", visible: true },
    { dataField: "Status", dataType: "string", caption: t("#_ImportConfig_StatusColumn"), width: "30%", visible: true,
        lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: statusDataSource(t),
        editorOptions: {
            dropDownOptions: {
                minWidth: '250px'
            }
        }
    },
    {
      dataField: "AllDataImported", dataType: "boolean", caption: t("#_ImportConfig_AllDataImportedColumn"), 
      width: "20%",  visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: allDataImportedDataSource(t),
      editorOptions: {
        dropDownOptions: {
          minWidth: '200px'
        }
      }
    },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: t("#_ImportConfig_ImportDateColumn"), width: "20%", cellRender: renderCreationDateCell, visible: true },
]

const allDataImportedDataSource = (t) => {
return {
store: {
  type: 'array',
  data: [
    { name: true, description: t("#_AllDataImported_True") },
    { name: false, description: t("#_AllDataImported_false") },
  ],
  key: 'name'
},
}
}

const statusDataSource = (t) => {
    return {
      store: {
        type: 'array',
        data: [
          { name: "NotStarted", description: t("#_ConfigImport_NotStarted") },
          { name: "InProgress", description: t("#_ConfigImport_InProgress") },
          { name: "Completed", description: t("#_ConfigImport_Completed") },
        ],
        key: 'name'
      },
    }
  }