export const batchPackageGridType =
{
    DispatchType: {
        Id: 0,
        Name: 'Sprzedaż - Wsadowa wysyłanie dokumentów'
    },
    SelfInvoicingType: {
        Id: 1,
        Name: 'Samofakturowanie - Wsadowa wysyłanie dokumentów'
    },
    OtherType: {
        Id: 2,
        Name: 'Inne - Wsadowa wysyłanie dokumentów'
    }
}

export const buttonTypes = [
    "Processing",
    "Failed",
    "Repo"
]