import { useRef } from "react"
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';

const labelMode = 'outside'
const labelLocation = 'top'
const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300
const fileExtensions = ['.json'];
const fileTypes = ".json"

const DocumentTemplateEditor = ({
    state,
    vgForm,
    setFile,
    file,
    t,
    getFirstError, 
    noErrors, 
    clearError
}) => {
    const fileUploaderRef = useRef()

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField);
        }
    }

    return <> <Form
        id="form"
        ref={vgForm}
        labelMode={labelMode}
        readOnly={readOnly}
        showColonAfterLabel={showColonAfterLabel}
        labelLocation={labelLocation}
        minColWidth={minColWidth}
        formData={state}
        onFieldDataChanged={formFieldDataChanged}
        className={"xfx-form"}>
        <GroupItem>
            <SimpleItem dataField="name" cssClass="xfx-required" editorType="dxTextBox" value={state.name}
                editorOptions={{
                    validationErrors: getFirstError("name"),
                    isValid: noErrors("name")
                }}
            >
                <Label text={t("#_ImportConfig_NameLabel")} />
            </SimpleItem>

            <SimpleItem cssClass="xfx-required">
                <Label text={t("#_ImportConfig_FileLabel")} />
                <FileUploader
                    ref={fileUploaderRef}
                    accept={fileTypes}
                    allowCanceling={false}
                    selectButtonText={t("#_ImportConfig_SelectButton")}
                    labelText={t("#_documenttemplateseditor_15")}
                    multiple={false}
                    value={file}
                    showFileList={true}
                    uploadMode="instantly"
                    uploadFile={async (fileIn) => {clearError("file");  setFile([fileIn]);}}
                    allowedFileExtensions={fileExtensions}
                    invalidFileExtensionMessage={t("#_documenttemplateseditor_16")}
                    uploadedMessage={t("#_documenttemplateseditor_17")}
                    readyToUploadMessage={t("#_documenttemplateseditor_18")}
                    uploadFailedMessage={t("#_documenttemplateseditor_19")}
                    name={'file'}
                    validationErrors={getFirstError("file")}
                    isValid={noErrors("file")}
                />
            </SimpleItem>
        </GroupItem>
    </Form></>
}

export default DocumentTemplateEditor