import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import '@fortawesome/fontawesome-svg-core/styles.css'
import './styles/styles.scss'
import './styles/base.css'
import './styles/base.scss'
import { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { UserSettingsProvider } from './contexts/UserSettingsProvider';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { InitLocalization } from 'localization/i18n'
import useInactivityLogout from 'utils/useInactivityLogout'


function App() {

  const { user, loading, setSelectTenant, signOut } = useAuth();
  const [selectTenantStep, setSelectTenantStep] = useState(false)
  const [availableTenats, setAvailableTenants] = useState([])
  const [selectCallback, setSelectCallback] = useState()

  useEffect(() => {
    setSelectTenant({
      select: (tenants) => new Promise((resolve, reject) => {
        setAvailableTenants(tenants)
        console.log(tenants)
        setSelectTenantStep(true)

        setSelectCallback({
          onClick: (tenantId) => {
            setSelectTenantStep(false)
            resolve(tenantId)
          }
        })

      }, () => { })
    })
    InitLocalization()
  }, [setSelectTenant])

  useInactivityLogout(signOut, user || selectTenantStep);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }
  return <UnauthenticatedContent selectTenantStep={selectTenantStep} availableTenats={availableTenats} selectCallback={selectCallback} />;

}

const Xfx = () => {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <UserSettingsProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </UserSettingsProvider>
      </AuthProvider>
    </Router>
  );
}

export default Xfx;