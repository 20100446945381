import { XfXApi } from "api/XfXApi";
import notify from "devextreme/ui/notify";

export const sendDraft = async (draftId: string, organizationId: string, onsuccess: any): Promise<boolean> => {
        await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftSendIdPut
                (draftId, XfXApi.GetTenantId(), organizationId).then(x => {
                        if (x.status === 200) {
                                notify("Faktura została przekazana do wysyłki", "success", 2000);
                                onsuccess()
                        }
                        return true;
                }).catch(x => {
                        notify(x.response?.data?.XfxDetails?.join(', '), "error", 2000);
                        return false;
                }
                );

        return true;
}
